import React, { 
    useState, 
    useEffect, 
    useCallback
} from "react";
import { 
    BrowserRouter,
    Routes,
    Route,
    useNavigate,
    Link,
    useLocation,
    useSearchParams
} from "react-router-dom";
import LayoutDashboard from "./layout/Layout";
import HomePage from "./pages/HomePage";

function App() {
    return (
        <div className="App">
            <BrowserRouter >
                <LayoutDashboard>
                    <HomePage />
                </LayoutDashboard>
            </BrowserRouter>
        </div>
    );
}

export default App;
