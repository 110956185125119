import React, { useState, useEffect } from "react";

import R_Eth from '../assets/images/icons/ri_eth.svg';

const NftItem = ({item, id}) => {
    return (
        <>
            <div className="market_item" key={id}>
                <img src={item.img} alt="" />
                <p className="market_item_title">{item.title}</p>
                <h3 className="market_item_price"><img src={R_Eth} alt="" />{item.price}</h3>
            </div>
        </>
    );
}

export default NftItem;
