import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Logo from '../assets/images/Logo.svg';
import LineBorder from '../assets/images/icons/line_title.svg';
import FooterBg from '../assets/images/footerBg.png';


const FooterPage = (props) => {

    const [collapsed, setCollapsed] = useState(true);

    return (
        <>
            <footer className="footer_wrap" style={{backgroundImage: `url(${FooterBg})` }}>
                {/* container start */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="footer-top">
                                <img src={Logo} alt="Logo" width={250} />
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#marketplace">
                                            Marketplace
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#news">
                                            News
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#whitepaper">
                                            Whitepaper
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#privacy">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#terms">
                                            Terms of use
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="line-center"></div>
                            <div className="footer-bottom">
                                <h2>Join Our Discord and Twitter Community</h2>
                                <div className="d-flex align-items-center justify-content-center">
                                    <a href="#" className="x-btn btn">
                                        TWitter
                                    </a>
                                    <a href="#" className="discord-btn btn">
                                        Discord
                                    </a>
                                </div>
                                <img src={LineBorder} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* container end */}
            </footer>
        </>
    );
}

export default FooterPage;
