import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';


import Button from "../components/Button";
import NftItem from "../components/Nft";

import BannerSection from '../assets/images/Hero-section.jpg';
import Model1 from '../assets/images/Model 1.svg';
import Bg_Model from '../assets/images/BG.png';
import Video_Footer from '../assets/images/Trailer_footer.png';
import Video_Banner from '../assets/images/video_banner_1.png';
import Arrow_Left from '../assets/images/Arrow_left.svg';
import Arrow_Right from '../assets/images/Arrow_right.svg';
import GamePlayBg from '../assets/images/gamePlayBg.png';
import GameStoryBg from '../assets/images/GameStoryBg.svg';
import GameStoryBg_2 from '../assets/images/GamePlayBg.jpg';
import HeroGameStory from '../assets/images/GamePlayHero.png';
import Tab_1 from '../assets/images/icons/Tab_1.svg';
import Tab_2 from '../assets/images/icons/Tab_2.svg';
import Tab_3 from '../assets/images/icons/Tab_3.svg';
import Tab_4 from '../assets/images/icons/Tab_4.svg';
import Tab_5 from '../assets/images/icons/Tab_5.svg';
import GamePlayBorder from '../assets/images/gamePlayBorder.svg';
import Skill_1 from '../assets/images/icons/Skill_1.svg';
import Skill_2 from '../assets/images/icons/Skill_2.svg';
import NetWorkBg from '../assets/images/coop_bg.png';
import NetWorkBorder from '../assets/images/coop_border.svg';
import RuleIcon from '../assets/images/icons/rule.svg';
import LineBorder from '../assets/images/icons/line_title.svg';
import PlayImg from '../assets/images/Image_slide.png';
import PlayBg from '../assets/images/playBg.png';
import Play_Icon_Left from '../assets/images/icons/play_left.svg';
import Play_Icon_Right from '../assets/images/icons/play_right.svg';
// nft
import Nft_Bg from '../assets/images/nft_play.png';
import Nft_img from '../assets/images/nft/Items.png';
import Gun from '../assets/images/nft/gun.png';
import Gun_1 from '../assets/images/nft/gun1.png';
import Gun_2 from '../assets/images/nft/gun2.png';
import Gun_3 from '../assets/images/nft/gun3.png';
import Gun_4 from '../assets/images/nft/gun4.png';
import Gun_5 from '../assets/images/nft/gun5.png';
import Gun_6 from '../assets/images/nft/gun6.png';
import Gun_7 from '../assets/images/nft/gun7.png';
import Gun_8 from '../assets/images/nft/gun8.png';
import Gun_9 from '../assets/images/nft/gun9.png';
import Gun_10 from '../assets/images/nft/gun10.png';
import Gun_11 from '../assets/images/nft/gun11.png';
import Gun_12 from '../assets/images/nft/gun12.png';
import Gun_13 from '../assets/images/nft/gun13.png';
import Gun_14 from '../assets/images/nft/gun14.png';
import Gun_15 from '../assets/images/nft/gun15.png';
import Gun_16 from '../assets/images/nft/gun16.png';
import Gun_17 from '../assets/images/nft/gun17.png';
// Trailer
import Trailer_1 from '../assets/images/trailer/1-min.PNG';
import Trailer_2 from '../assets/images/trailer/2-min.PNG';
import Trailer_3 from '../assets/images/trailer/3-min.PNG';
import Trailer_4 from '../assets/images/trailer/4-min.PNG';
import Trailer_5 from '../assets/images/trailer/5-min.PNG';
import Trailer_6 from '../assets/images/trailer/6-min.PNG';
import Trailer_7 from '../assets/images/trailer/7-min.PNG';
import Trailer_8 from '../assets/images/trailer/8-min.PNG';
import Trailer_9 from '../assets/images/trailer/9-min.PNG';
import Trailer_10 from '../assets/images/trailer/10-min.PNG';
import Trailer_11 from '../assets/images/trailer/11-min.PNG';
import Trailer_12 from '../assets/images/trailer/12-min.PNG';
import Trailer_13 from '../assets/images/trailer/13-min.PNG';
import Trailer_14 from '../assets/images/trailer/14-min.PNG';
import Trailer_15 from '../assets/images/trailer/15-min.PNG';
import Trailer_16 from '../assets/images/trailer/16-min.PNG';
import Trailer_17 from '../assets/images/trailer/17-min.PNG';
import Trailer_18 from '../assets/images/trailer/18-min.PNG';
import Trailer_19 from '../assets/images/trailer/19-min.PNG';
import Trailer_20 from '../assets/images/trailer/20-min.PNG';

import Socials_Bg from '../assets/images/Helmet.png';
import Socials_Border from '../assets/images/social_border.svg';
import AppleStoreIcon from '../assets/images/icons/AppStore.svg';
import GoolePlayIcon from '../assets/images/icons/GooglePlay.svg';

// mobile img

import BannerShapeMb from '../assets/images/icons/banner_shape_mb.svg';
import StoryShapeMb from '../assets/images/icons/story_shape_mb.svg';
import GamePlayShapeMb from '../assets/images/icons/gamePlay_shape_mb.svg';
import NwShapeMb from '../assets/images/icons/nw_shape_mb.svg';
import SocialShapeMb from '../assets/images/icons/social_shape_mb.svg';

import SwiperCore from 'swiper';
SwiperCore.use([Navigation]);

const nfts = [
    {
        id: 1,
        title: 'CH66 2RD',
        price: '1,4 ETH',
        img: Gun_1
    },
    {
        id: 2,
        title: 'HG4 2TE',
        price: '1,4 ETH',
        img: Gun_2
    },
    {
        id: 3,
        title: 'BT78 4RH',
        price: '1,4 ETH',
        img: Gun_3
    },
    {
        id: 4,
        title: 'NP7 0LT',
        price: '1,4 ETH',
        img: Gun_4
    },
    {
        id: 5,
        title: 'NW6 4HA',
        price: '1,4 ETH',
        img: Gun_5
    },
    {
        id: 6,
        title: 'KT12 2SX',
        price: '1,4 ETH',
        img: Gun_6
    },
    {
        id: 7,
        title: 'LL14 1ER',
        price: '1,4 ETH',
        img: Gun_7
    },
    {
        id: 8,
        title: 'HU17 8JJ',
        price: '1,4 ETH',
        img: Gun_8
    },
    {
        id: 9,
        title: 'MA5D assault rifle',
        price: '1,4 ETH',
        img: Gun_9
    },
    {
        id: 10,
        title: 'CW12 3NB',
        price: '1,4 ETH',
        img: Gun_10
    },
    {
        id: 11,
        title: 'LE67 4AN',
        price: '1,4 ETH',
        img: Gun_11
    },
    {
        id: 12,
        title: 'TD12 4BZ',
        price: '1,4 ETH',
        img: Gun_12
    },
    {
        id: 13,
        title: 'ME1 1YL',
        price: '1,4 ETH',
        img: Gun_13
    },
    {
        id: 14,
        title: 'HA5 2HS',
        price: '1,4 ETH',
        img: Gun_14
    },
    {
        id: 15,
        title: 'G72 7ND',
        price: '1,4 ETH',
        img: Gun_15
    },
    {
        id: 16,
        title: 'LS8 3DN',
        price: '1,4 ETH',
        img: Gun_16
    },
    {
        id: 17,
        title: 'CM7 5EY',
        price: '1,4 ETH',
        img: Gun_17
    },
    {
        id: 18,
        title: 'KY10 3AU',
        price: '1,4 ETH',
        img: Gun
    },

]

const Wrapper = styled.section`
    background-image: url(${BannerSection});
    background-size: cover;
    background-repeat: no-repeat;
`;

const NetworkWrapper = styled.div`
    background-image: url(${NetWorkBg});
    background-size: cover;
    background-repeat: no-repeat;
`;

const GameStoryBgWrap = styled.section`
    // background-image: url(${BannerSection});
    background: linear-gradient(176deg, #02060B 1.43%, rgba(2, 6, 11, 0.00) 44%), radial-gradient(225.52% 50% at 50% 52.05%, rgba(2, 6, 12, 0.00) 0%, #001125 100%), url(${GameStoryBg_2}), lightgray -93.182px -47.336px / 113.294% 113.294% no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
`;

const PlaySectionWrap = styled.section`
    background-image: url(${PlayBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
`;

const BackgroundContainer = styled.div`
    background-image: url(${Bg_Model});
    background-size: 100%;
    background-repeat: no-repeat;
`;

const BackgroundHeroFooter = styled.div`
    background: linear-gradient(180deg, rgba(6, 6, 6, 0.00) 82.45%, #060606 100%);
    width: 100%;
    height: 300px;
    position: absolute;
    right: 0px;
    bottom: 0px;
`

const VideoFooter = styled.div`
    background-image: url(${Video_Footer});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const VideoFooterContainer = styled.div`
    text-align: center;
`;

const HomePage = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);

    const paginationOptions = {
        el: '.video-pagination',
        clickable: true,
        renderBullet: (index, className) => (
            `<span class="${className}"></span>`
        ),
    };

    const navigationOptions = {
        nextEl: '.video-button-next',
        prevEl: '.video-button-prev',
    };

    // Thay đổi activeIndex khi swiper thay đổi
    const handleSlideChange = () => {
        if (swiperRef.current) {

            setActiveIndex(swiperRef.current.swiper.activeIndex);
        }
    };

    // Bấm vào item từ danh sách
    const handleListItemClick = (index) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(index);
        }
    };

    // Next và Prev buttons
    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    // Cập nhật activeIndex khi thay đổi
    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(activeIndex);
        }
    }, [activeIndex]);

    return (
        <React.Fragment>
            <Wrapper className="banner_section">
                <BackgroundContainer className="banner_bg">
                    {/* vertical animation line */}
                    <div className="container">
                        {/* row start */}
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                {/* banner text */}
                                <div className="banner_text">
                                    {/* h1 */}
                                    <h1>
                                        Huntcraft: The Last Stand
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                {/* banner text */}
                                <div className="banner_img">
                                    <img src={Model1} alt="" />
                                </div>
                            </div>
                            {/* banner slides start */}
                            <div className="col-lg-4 col-md-12">
                                <div className="banner_text_right">
                                    <h2>GAME PASS</h2>
                                    <p>Immerse yourself in a post-apocalyptic world, facing hunger, strange creatures, and the battle for your tribe's survival.</p>
                                    <Button text="Get game pass" />
                                    <button className="btn btn-rule text-uppercase text-white fw-bold">
                                        <img src={RuleIcon} alt="" />
                                        <span className="ms-2">The Rule</span>
                                    </button>

                                </div>
                            </div>
                            {/* banner slides end */}
                        </div>
                        {/* row end */}
                    </div>
                </BackgroundContainer>
                <div className="banner_bg_mobile">
                    {/* vertical animation line */}
                    <div className="container">
                        {/* row start */}
                        <div className="row">
                            <div className="col-12">
                                <div className="banner_text">
                                    <h1>
                                        Huntcraft: The Last Stand
                                    </h1>
                                </div>
                                <div className="banner_img">
                                    <img src={Model1} alt="" />
                                </div>
                                <div className="banner_text_right" style={{ backgroundImage: `url(${BannerShapeMb})` }}>
                                    <h2>GAME PASS</h2>
                                    <p>Immerse yourself in a post-apocalyptic world, facing hunger, strange creatures, and the battle for your tribe's survival.</p>
                                    <Button text="Get game pass" />
                                    <button className="btn btn-rule text-uppercase text-white fw-bold">
                                        <img src={RuleIcon} alt="" />
                                        <span className="ms-2">The Rule</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </div>
                <BackgroundHeroFooter />
            </Wrapper>
            <section className="video_section section-maxWidth">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <Swiper
                                modules={[Autoplay, Pagination, Navigation]}
                                spaceBetween={50}
                                slidesPerView={1}
                                loop={true}
                                pagination={paginationOptions}
                                navigation={navigationOptions}
                                scrollbar={{ draggable: true }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                            >
                                <SwiperSlide>
                                    <img src={Trailer_1} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_4} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_5} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_6} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_7} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_8} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_9} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_10} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_11} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_12} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_13} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_14} alt="" />
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <img src={Trailer_15} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_16} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_17} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_18} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_19} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Trailer_20} alt="" />
                                </SwiperSlide> */}
                            </Swiper>
                            <VideoFooter className="video_footer">
                                <VideoFooterContainer className="video_footer_container">
                                    <div className="video-button-prev">
                                        <img src={Arrow_Left} alt="" />
                                    </div>
                                    <div className="video-pagination"></div>
                                    <div className="video-button-next">
                                        <img src={Arrow_Right} alt="" />
                                    </div>
                                </VideoFooterContainer>
                            </VideoFooter>
                            <div className="section_title">
                                <h2>TRAILER</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="story_section section-maxWidth">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="game_story_box" style={{ backgroundImage: `url(${GameStoryBg})` }}>
                                <h2>The Story</h2>
                                <h4>Fierce Battlefield</h4>
                                <p>In the post-apocalyptic world of Huntcraft, water and food become important targets, causing fierce confrontation between humans and nature. Great tribes and alliances form to fight, establish rules to protect the race and manage resources. The population declined significantly and organisms evolved to adapt to the harsh environment. Players embark on dangerous adventures, from hunting to engaging in combat with intelligent evolved creatures. The battlefield is the ultimate arena, where players face high-level challenges and can decide the fate of an entire community. Huntcraft is not only an entertaining game but also a profound journey, taking players into a diverse and dangerous world where every decision has a major impact on the story and building community</p>
                            </div>
                            <div className="game_story_box_mb" style={{ backgroundImage: `url(${StoryShapeMb})` }}>
                                <h2>The Story</h2>
                                <h4>Fierce Battlefield</h4>
                                <p>In the post-apocalyptic world of Huntcraft, water and food become important targets, causing fierce confrontation between humans and nature. Great tribes and alliances form to fight, establish rules to protect the race and manage resources. The population declined significantly and organisms evolved to adapt to the harsh environment. Players embark on dangerous adventures, from hunting to engaging in combat with intelligent evolved creatures. The battlefield is the ultimate arena, where players face high-level challenges and can decide the fate of an entire community. Huntcraft is not only an entertaining game but also a profound journey, taking players into a diverse and dangerous world where every decision has a major impact on the story and building community</p>
                            </div>
                        </div>
                    </div>

                </div>
                <img src={GamePlayBg} alt="" className="story_section_img" />
                <GameStoryBgWrap className="gamePlay_section">
                    <div className="container">
                        <div className="row justify-content-end align-items-center">
                            <div className="col-lg-7 col-md-12">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={12} className="show_desktop">
                                            <Nav className="gamePlay-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">
                                                        <img src={Tab_1} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">
                                                        <img src={Tab_2} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">
                                                        <img src={Tab_3} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="four">
                                                        <img src={Tab_4} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="five">
                                                        <img src={Tab_5} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12} className="show_mb">
                                            <Nav className="gamePlay-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">
                                                        <img src={Tab_1} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">
                                                        <img src={Tab_2} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">
                                                        <img src={Tab_3} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="four">
                                                        <img src={Tab_4} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="five">
                                                        <img src={Tab_5} alt="" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayShapeMb})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <div className="line"></div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayShapeMb})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayShapeMb})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="four">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayShapeMb})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="five">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayShapeMb})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                        <Col sm={12} className="show_desktop">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayBorder})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <div className="line"></div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayBorder})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayBorder})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="four">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayBorder})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="five">
                                                    <div className="gamePlay-box" style={{ backgroundImage: `url(${GamePlayBorder})` }}>
                                                        <h2>The Avelorian</h2>
                                                        <p>From the mystical land of Avalon, Avelorians live sustainably in a post-apocalyptic world. Their strength comes from their ability to endure and expect natural regeneration. Buffalo blood and trust are powerful weapons, symbols of sustainable vitality.</p>
                                                        <h5>Skill Set </h5>
                                                        <div className="d-flex gap-1">
                                                            <img src={Skill_1} alt="" width={44} />
                                                            <img src={Skill_2} alt="" width={44} />
                                                        </div>
                                                        <h5>Individual</h5>
                                                        <ul>
                                                            <li>Quickly restores 30% health in the first 1 second, 20% health in the next 20 seconds</li>
                                                            <li>Healed allies restore 40% health</li>
                                                        </ul>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                    <img src={HeroGameStory} alt="" />
                </GameStoryBgWrap>
            </section>
            <section className="network_section section-maxWidth">
                <NetworkWrapper className="network_banner" />
                <div className="network_container" style={{ backgroundImage: `url(${NetWorkBorder})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <h2 className="network_title">
                                    CAMPAIGN NETWORK CO-OP
                                </h2>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="nw-left">
                                    <h2 className="network_left_title">
                                        Game mode
                                    </h2>
                                    <p>Explore the diversity of Huntcraft's game modes, from intense single combat experiences to dramatic squad battlefields, promising multi-dimensional challenges and unique squad experiences</p>
                                    <Button text="Get game pass" />
                                    <button className="btn btn-rule text-uppercase text-white fw-bold">
                                        <img src={RuleIcon} alt="" />
                                        <span className="ms-2">The Rule</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="nw-right">
                                    <Accordion className="network_accordion" defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Arena</Accordion.Header>
                                            <Accordion.Body>
                                                Battlefield mode in Huntcraft is where players participate in dramatic matches, fighting with evolved creatures and competing between large tribes to control water sources and important areas. This experience stimulates cooperation and competition, creating a world full of drama and strategy.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Battle Field</Accordion.Header>
                                            <Accordion.Body>
                                                Battlefield mode in Huntcraft is where players participate in dramatic matches, fighting with evolved creatures and competing between large tribes to control water sources and important areas. This experience stimulates cooperation and competition, creating a world full of drama and strategy.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="network_container_mb" style={{ backgroundImage: `url(${NwShapeMb})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <h2 className="network_title">
                                    CAMPAIGN NETWORK CO-OP
                                </h2>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="nw-left">
                                    <h2 className="network_left_title">
                                        Game mode
                                    </h2>
                                    <p>Explore the diversity of Huntcraft's game modes, from intense single combat experiences to dramatic squad battlefields, promising multi-dimensional challenges and unique squad experiences</p>
                                    <Button text="Get game pass" />
                                    <button className="btn btn-rule text-uppercase text-white fw-bold">
                                        <img src={RuleIcon} alt="" />
                                        <span className="ms-2">The Rule</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="nw-right">
                                    <Accordion className="network_accordion" defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Arena</Accordion.Header>
                                            <Accordion.Body>
                                                Battlefield mode in Huntcraft is where players participate in dramatic matches, fighting with evolved creatures and competing between large tribes to control water sources and important areas. This experience stimulates cooperation and competition, creating a world full of drama and strategy.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Battle Field</Accordion.Header>
                                            <Accordion.Body>
                                                Battlefield mode in Huntcraft is where players participate in dramatic matches, fighting with evolved creatures and competing between large tribes to control water sources and important areas. This experience stimulates cooperation and competition, creating a world full of drama and strategy.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PlaySectionWrap className="play_section section-maxWidth">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="play_title">
                                <h2>
                                    NOT ONLY PLAY THE GAME PLAY-TO-FREE
                                </h2>
                                <img src={LineBorder} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="play_tabWrapper">
                                <Tabs
                                    defaultActiveKey="play"
                                    className="play_tab"
                                >
                                    <Tab eventKey="play" title="Play">
                                        <div className="play_img">
                                            <img src={PlayImg} alt="" />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="collect" title="Collect">
                                        <div className="play_img">
                                            <img src={PlayImg} alt="" />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="own" title="Own">
                                        <div className="play_img">
                                            <img src={PlayImg} alt="" />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className="play_box_wrap d-flex justify-content-center">
                                <div className="play_box text-center">
                                    <img src={Tab_5} alt="" />
                                    <h2>42,315,134</h2>
                                    <p>Total Hunters</p>
                                </div>
                                <div className="play_box text-center">
                                    <img src={Tab_5} alt="" />
                                    <h2>11,482</h2>
                                    <p>Items found</p>
                                </div>
                                <div className="play_box text-center">
                                    <img src={Tab_5} alt="" />
                                    <h2>$3B</h2>
                                    <p>Total Sales</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="play_left_icon" src={Play_Icon_Left} alt="" />
                <img className="play_right_icon" src={Play_Icon_Right} alt="" />
                <div className="play_line"></div>
            </PlaySectionWrap>
            <section className="market_section section-maxWidth" style={{ backgroundImage: `url(${Nft_Bg})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="market_title">
                                <h2>
                                    MARKETPLACE
                                </h2>
                                <img src={LineBorder} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="market_slide show_desktop">
                                <Swiper
                                    ref={swiperRef}
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    centeredSlides={true}
                                    centeredSlidesBounds={true}
                                    roundLengths={true}
                                    navigation={{ nextEl: '.market-button-next', prevEl: '.market-button-prev' }}
                                    onSlideChange={handleSlideChange}
                                >
                                    {nfts.map((item, index) => (
                                        <SwiperSlide key={index} >
                                            <NftItem item={item} key={index} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="market_slide_navigation">
                                    <div className="market-button-prev" onClick={handlePrevClick}>
                                        <img src={Arrow_Left} alt="" />
                                    </div>
                                    <div className="market-button-next" onClick={handleNextClick}>
                                        <img src={Arrow_Right} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="market_slide show_mb">
                                <Swiper
                                    ref={swiperRef}
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    centeredSlides={true}
                                    centeredSlidesBounds={true}
                                    roundLengths={true}
                                    navigation={{ nextEl: '.market-button-next', prevEl: '.market-button-prev' }}
                                    onSlideChange={handleSlideChange}
                                >
                                    {nfts.map((item, index) => (
                                        <SwiperSlide key={index} >
                                            <NftItem item={item} key={index} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="market_slide_navigation">
                                    <div className="market-button-prev" onClick={handlePrevClick}>
                                        <img src={Arrow_Left} alt="" />
                                    </div>
                                    <div className="market-button-next" onClick={handleNextClick}>
                                        <img src={Arrow_Right} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3 mt-3">
                                <Button text="Sell your items" />
                            </div>
                            <div className="market_tabWrapper">
                                {
                                    nfts.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={activeIndex === index ? 'market_item_wrap active' : 'market_item_wrap'}
                                                onClick={() => handleListItemClick(index)}
                                            >
                                                <NftItem
                                                    item={item}
                                                    key={index}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="socials_wrap section-maxWidth" style={{ backgroundImage: `url(${Socials_Bg})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="social_left_wrap show_desktop" style={{ backgroundImage: `url(${Socials_Border})` }}>
                                <h2 className="text-center">BECOME A LEGEND</h2>
                                <div className="line-center"></div>
                                <p className="text-center">Immerse yourself in a post-apocalyptic world, facing hunger, strange creatures, and the battle for your tribe's survival.</p>
                                <div className="text-center mb-3 mt-3">
                                    <Button text="Get game pass" />
                                </div>
                                <div className="d-flex justify-content-center gap-1 social_item">
                                    <a href="#">
                                        <img src={AppleStoreIcon} alt="" />
                                    </a>
                                    <a href="#">
                                        <img src={GoolePlayIcon} alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="social_left_wrap show_mb" style={{ backgroundImage: `url(${SocialShapeMb})` }}>
                                <h2 className="text-center">BECOME A LEGEND</h2>
                                <div className="line-center"></div>
                                <p className="text-center">Immerse yourself in a post-apocalyptic world, facing hunger, strange creatures, and the battle for your tribe's survival.</p>
                                <div className="text-center mb-3 mt-3">
                                    <Button text="Get game pass" />
                                </div>
                                <div className="d-flex justify-content-center gap-1 social_item">
                                    <a href="#">
                                        <img src={AppleStoreIcon} alt="" />
                                    </a>
                                    <a href="#">
                                        <img src={GoolePlayIcon} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default HomePage;
