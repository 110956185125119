import React, { useState, useEffect } from "react";
import styled from 'styled-components';

import Bg_Btn from '../assets/images/Bg_btn.png';

const ButtonWrap = styled.button`
    background-image: url(${Bg_Btn});
    background-size: cover;
    background-repeat: no-repeat;
    width: 184px;
    height: 42px;
    color: var(--text-white);
    text-transform: uppercase;
    transition: .4s ease-in;
    font-family: 'Play';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    &:hover{
        color: var(--text-white);
        transform: scale(1.1);
    }
`

const Button = ({text}) => {

    const [collapsed, setCollapsed] = useState(true);

    return (
        <>
            <ButtonWrap className="btn">{text}</ButtonWrap>
        </>
    );
}

export default Button;
