import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Button from '../components/Button';
import Logo from '../assets/images/Logo.svg';
import AppStoreIcon from '../assets/images/App_store.svg';
import GoogleStoreIcon from '../assets/images/Google_store.svg';
import MenuIcon from '../assets/images/icons/Menu.svg';
import AppleStoreIcon from '../assets/images/icons/AppStoreMb.svg';
import GoolePlayIcon from '../assets/images/icons/GooglePlayMb.svg';
import MenuBorder from '../assets/images/icons/menu_border.svg';
import MenuClose from '../assets/images/icons/close.svg';
import MbShapeLeft from '../assets/images/icons/mb_shape0.svg';
import MbShapeRight from '../assets/images/icons/mb_shape1.svg';
import MbShapeBottom from '../assets/images/icons/mb_shape2.svg';

const HeaderPage = (props) => {

    const [collapsed, setCollapsed] = useState(true);

    const handleShowMenu = () => {
        setCollapsed(false)
    }

    return (
        <>
            <header className="">
                {/* container start */}
                <div className="container">
                    {/* navigation bar */}
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand d-block d-sm-none" to="#">
                            <img src={Logo} alt="Logo" />
                        </Link>
                        <button
                            className="navbar-mobile btn d-block d-sm-none"
                            type="button"
                            onClick={handleShowMenu}
                        >
                            <img src={MenuIcon} alt="" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link className="navbar-brand" to="#">
                                        <img src={Logo} alt="Logo" />
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#features">
                                        Intro
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#how_it_work">
                                        Trailer
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#story">
                                        The story
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#class">
                                        Class
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#marketplace">
                                        Marketplace
                                    </a>
                                </li>
                            </ul>
                            <ul className="navbar-right">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        <img src={AppStoreIcon} alt="" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        <img src={GoogleStoreIcon} alt="" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Button text="Get game pass" />
                                </li>
                            </ul>
                        </div>
                    </nav>
                    {/* navigation end */}
                </div>
                {/* container end */}
            </header>
            <div className={collapsed ? 'menu_mobile' : 'menu_mobile active'}>
                <img className="MbShapeLeft" src={MbShapeLeft} alt="" />
                <img className="MbShapeRight" src={MbShapeRight} alt="" />
                <img className="MbShapeBottom" src={MbShapeBottom} alt="" />
                <div className="colse_mb" onClick={() => setCollapsed(true)}>
                    <img src={MenuClose} alt="" />
                </div>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#features">
                            Intro
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#how_it_work">
                            Trailer
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#story">
                            The story
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#class">
                            Class
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#marketplace">
                            Marketplace
                        </a>
                    </li>
                    <li className="nav-item">
                        <Button text="Get game pass" />
                    </li>
                    <li className="nav-item">
                        <a href="#">
                            <img src={AppleStoreIcon} alt="" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#">
                            <img src={GoolePlayIcon} alt="" />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default HeaderPage;
